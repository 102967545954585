var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.name.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Nama Notifikasi")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.type.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Tipe Notifikasi")]), _c('c-select', {
    attrs: {
      "h": "62px",
      "placeholder": "Tipe Notifikasi"
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }, [_c('option', {
    attrs: {
      "value": "direct"
    }
  }, [_vm._v(" Direct ")]), _c('option', {
    attrs: {
      "value": "scheduler"
    }
  }, [_vm._v(" Schedule ")])])], 1)], 1), _vm.type === 'scheduler' ? _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.type === 'scheduler',
      "is-invalid": _vm.$v.dateTime.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Tanggal & Waktu Notifikasi")]), _c('c-input-group', [_c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        pointerEvents: 'none',
        '&[data-invalid]': {
          boxShadow: '0 0 0 1px #e66673 !important'
        }
      },
      expression: "{\n            pointerEvents: 'none',\n            '&[data-invalid]': {\n              boxShadow: '0 0 0 1px #e66673 !important',\n            },\n          }"
    }],
    attrs: {
      "h": "62px",
      "border-width": "1px",
      "type": "text"
    },
    model: {
      value: _vm.datetimeShow,
      callback: function callback($$v) {
        _vm.datetimeShow = $$v;
      },
      expression: "datetimeShow"
    }
  }), _c('c-input-right-element', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "62px",
      "w": "62px"
    }
  }, [_c('Popper', {
    attrs: {
      "trigger": "clickToOpen"
    }
  }, [_c('c-box', [_c('v-date-picker', {
    attrs: {
      "mode": "dateTime",
      "timezone": ''
    },
    model: {
      value: _vm.dateTime,
      callback: function callback($$v) {
        _vm.dateTime = $$v;
      },
      expression: "dateTime"
    }
  })], 1), _c('c-button', {
    attrs: {
      "slot": "reference",
      "variant": "solid",
      "rounded-left": "0",
      "rounded-right": "md",
      "h": "60px",
      "w": "60px",
      "size": "md",
      "m": "0",
      "p": "0",
      "min-w": "0"
    },
    slot: "reference"
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-clock.svg'),
      "alt": "show date icon",
      "size": "24px"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.content.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Isi Notifikasi")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text"
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.target.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Tujuan Notifikasi")]), _c('c-checkbox-group', {
    attrs: {
      "spacing": "10px",
      "variant-color": "primary"
    },
    model: {
      value: _vm.target,
      callback: function callback($$v) {
        _vm.target = $$v;
      },
      expression: "target"
    }
  }, [_c('c-checkbox', {
    attrs: {
      "value": "client"
    }
  }, [_vm._v(" Klien ")]), _c('c-checkbox', {
    attrs: {
      "value": "nutritionist"
    }
  }, [_vm._v(" Ahli Gizi ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }